module.exports = [
    {
        path: '/',
        redirect: '/de',
        name: 'Home',
    },
    {
        path: '/de',
        name: 'Home - DE',
        component: () => import('../views/Home.vue'),
        props: {language: 'de'}
    },
    {
        path: '/en',
        name: 'Home - EN',
        component: () => import('../views/Home.vue'),
        props: {language: 'en'}
    },
    {
        path: '/legal',
        name: 'Legal',
        component: () => import('../views/Legal.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/Privacy.vue')
    }
]
