<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Homepage',
    titleTemplate: '%s | Moritz Witte | Software Developer'
  }
}
</script>
<style>
/** Global **/
body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #121212;
  height: 100%;
}

a {
  color: #03DAC6;
  text-decoration: unset;
}

@media (max-width: 767px) {
  .vertical-center {
    padding-top: 0 !important;
  }

  .content {
    height: unset;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}
</style>
