import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import routes from './routes'

Vue.use(VueRouter)
Vue.use(Meta, {
    keyName: 'metaInfo',
    refreshOnceOnNavigation: true
})

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
